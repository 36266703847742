/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
body{
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */

/* css start from here */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
.inner_loginform_container{
  padding-bottom: 100px;
}
body{
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  font-family: "Lato", sans-serif;
}
.logo_container{
  height: 30px;
  width: 200px;
  background-image: url("../public/images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.header_nav{
  background-color: #C6222F;
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.custom_header_container{
  width: 100%;
  height: max-content;
  position: fixed;
  top: 0;
  z-index: 10;
}
.custom_bottom_container{
  width: 100%;
  height: max-content;
  position: fixed;
  bottom: 0;
  z-index: 10;
}
.menu_container{
  height: max-content;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
}
/* .main_form_container{
  min-height: 100vh !important;
} */
.login_section{
  width: 100%;
  /* min-height: 90vh; */
  max-height: max-content;
  display: flex;
}
.login_section1{
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  max-height: max-content;
  display: flex;
  padding: 3.1rem 0 0 0 ;
}
.login_sidebar,.login_sidebar_multiform{
  flex: 2;
  background-image: linear-gradient(180deg, #c62230d3,#c62230d3,#c62230d3, #c62230d3 70%) , url('../public/images/sideBar.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: 1px solid gainsboro;
}
.login_sidebar1{
  flex: 2;
  background-color: #C6222F;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: 1px solid gainsboro;
}
/* .textarea{
  width: 70%;
  height: 60px;
} */
.textarea_class{
 border-radius: 2px;
 outline: none;
 border-color: #DDCDC0;
 background-color: transparent;
}
.height_input_feild_container{
  width: 60%;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.inner_login_sidebar{
  position: absolute;
  top: 80px;
}
.loginBtn{
  padding: 0.7rem 4rem;
  border: none;
  background-color: #C6222F;
  color: #fff;
  font-size: 0.85rem;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  letter-spacing: 0.09rem;
  text-transform: uppercase;
  font-weight: 500;
}
.loginBtnBack{
  padding: 0.7rem 4rem;
  border: 1px solid#C6222F;
  color: #C6222F;
  font-size: 0.85rem;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  letter-spacing: 0.09rem;
  text-transform: uppercase;
  font-weight: 600;
}
.login_form_container{
  flex: 3;
  background-color: #F9F6F2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8rem;
  padding-top: 80px;
  /* background-color: yellow; */
}
.title{
font-family: Frank Ruhl Libre;
font-size: 1.9rem;
font-weight: 200;
line-height: 100px;
text-align: left;
color: #C6222F;
margin-bottom: 1rem;
}
input{
  border: none;
  background-color: transparent;
}
label{
  font-family: "Lato", sans-serif;
  display: block;
  font-size: 0.85rem;
  font-weight: 600;
  /* letter-spacing: 0.06em; */
  text-align: left;
  color: #212121;  
}
.inpufeild{
  /* margin-top: 0.5rem; */
  padding: 1rem;
  padding-left: 1rem;
  padding-bottom: 0.7rem;
  border: none;
  border-bottom: 1px solid #DDCDC0;
  outline: none;
  background-color: transparent;
  width: 45%;
}
.radio_container{
  width: 45%;
  padding: 1rem;
}
input[type="radio"]{
  accent-color: #C6222F;
}
.form_text{
   color: #C6222F;
   font-weight:500;
   margin-top: 1rem;
   font-size: 0.85rem;
}
input::placeholder{
  font-family: "Lato", sans-serif;
font-size: 0.85rem;
font-weight: 500;
line-height: 21.6px;
/* letter-spacing: 0.05em; */
text-align: left;
color: #5B5B5B;
/* text-transform:capitalize !important; */
}
.copy_right_container{
  /* height: 70px; */
  padding: 0.7rem;
  width: 100%;
  background-color: #000;
  color: #fff;
  text-align: center;
  /* position: absolute; */
  bottom: 0;
}
.images_icon{
  height: 40px;
  width: 40px;
}
input[type=date]::-webkit-calendar-picker-indicator {
  /* visibility: hidden; */
  
}
.navigation{
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: #fff;
}
.navigation p{
  color: #fffffff6;
  margin-bottom: 0px !important;
}
.btn_container{
  display: flex;
  gap: 2rem;
  width: max-content;
  margin-top: 1.8rem;
}
.personal_divider,.step2_divider,.family_divider,.professional_divider,.moreaboutyou_divider,.yourphotograph_divider,.submit_divider{
  height: 2rem;
  width: 0.005rem;
  background-color: #fff;
  margin: auto;
}
.active_divider{
  width: 0.2rem;
}
.outer_divider{
  width: 2.4rem;
}
.select_height{
  border: none;
  border-bottom: 1px solid #DDCDC0;
  padding: 1rem 2rem;
  background-color: transparent;
  outline: none;
}
.confirmation_text_container{
  width: 60%;
  display: flex;
  gap: 0.5rem;
  align-items: start;
}
input::placeholder{
  color: #5B5B5B;
  opacity: 0.5;
}
.userphoto{
  height: 1.2rem;
  width: 1.2rem;
  background-image: url("../public/images/userphoto.jpeg");
  background-repeat: no-repeat;
  background-size: contain;
}
.camerastartCapture{
  height: 8rem;
  width: 8rem;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
}
.camerastartCapture_btn{
  border: none;
  outline: none;
  background-color: transparent;
  margin: auto;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}
.cameraIcon{
  height: 3rem;
  width: 3rem;
}
.camerastartCapture_overlay{
  /* border-radius: 1rem; */
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.192); */
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.previewPhoto{
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.previewPhoto img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.custom_body_modal video{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.captured{
  border-radius: 1rem;
}
.submitted_Title{
  font-family: Frank Ruhl Libre;
  color: #C6222F;
  font-size: 35px;
}
.submitted_content_container{
  width: 50%;
  height: max-content;
  padding-top: 100px;
  /* padding-bottom: 50px; */
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.submitted_description{
  width: 350px;
}
.submit_animation{
  height: 250px;
  width: 250px;
  /* margin-bottom: 50px; */
  /* background-color: yellow; */
}
.submit_animation img{
 height: 100%;
 width: 100%;
 object-fit: cover;
}
.profile_page{
  /* height: 100vh; */
  width: 100%;
  display: flex;
  padding: 1rem;
  background-color: #FAFAFA;
  /* overflow: scroll; */
}
.profile_image_container{
  height: 250px;
  width: 250px;
  margin: auto;
}
.user_name{
  margin-top: 1rem;
  font-family: "Lato", sans-serif;
  color: #C6222F;
  text-align: center;
}
.profile_button{
  padding: 0.5rem;
  min-width: 200px;
  height: max-content;
  width: max-content;
  border: none;
  background-color: #C6222F;
  color: #fff;
  font-weight: 500;
  display: block;
  outline: none;
  font-family: "Lato", sans-serif;
  border-radius: 0.5rem;
}
.profile_navbar{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.profile_left_container{
  flex: 1;
  /* height: max-content; */
  /* overflow: scroll; */
  scrollbar-color: #fff;
}
.profile_right_container{
  flex: 4;
  height: 100%;
  /* overflow: scroll; */
  /* background-color: #fff; */
}
.personal_info_container{
  padding: 0 2rem;
  font-family: "Lato", sans-serif !important;
}
.personal_content{
  width: 50%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  font-family: "Lato", sans-serif !important;
  color: #a5a4a4;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.7rem;
}
.inner_personal_info_container{
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #DEDEDE;
  padding: 2rem 0;
}
.inner_profile_right_container{
  height: max-content;
  width: 90%;
  margin: auto;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0dfdf; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c5c4c4; 
}
.header_container{
  width: 100%;
  height: max-content;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 100;
}
.header_dropdown{
  height: 180px;
  width: 200px;
  background-color: #fff;
  position: absolute;
  right: 80px;
  box-shadow: 1px 2px 2px 2px #c5c4c4;
  padding: 1.5rem 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.dropdown_menu_container{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 0.3rem;
}
.kyc_view_page{
  width: 100%;
  height: 90vh;
  /* padding: 1rem; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kyc_list_card{
  height: 100px;
  width: 100%;
  box-shadow: 2px 2px 8px 2px gainsboro;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0;
  padding-right: 1rem;
}
.kyc_left_view{
  /* flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; */
  /* background-color: yellow; */
}
.kyc_right_view{
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;
  /* justify-content: center; */
  /* background-color: hotpink; */
}
.kyc_card{
  height: 100%;
  width: 100%;
  /* box-shadow: 2px 2px 8px 2px gainsboro; */
  border-radius: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  text-align: center;
  padding: 2rem;
  gap: 0.5rem;
}
.kyc_card_inputfield{
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  justify-content: center;
}
.kyc_card h3{
  font-family: "Lato", sans-serif !important;
}
.kyc_card h6{
color: #a5a4a4;
}
.kyc_upload_button{
  padding: 0.6rem 2.5rem;
  background-color: #C6222F;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  outline: none;
}
.kyc_images_container{
  width: 100px;
  height: 100px;
}
/* .choose_file_button{
color: #fff;
background-color: #C6222F;
border: none;
} */
.profile_view_button{
  padding: 0.2rem 1rem;
  border: none;
  background-color: #C6222F;
  color: #fff;
}
.kyc_list_container{
  width: 100%;
  margin: auto;
}
.ant-modal-close{
  display: none;
}

.kyc_view_page{
  overflow: scroll;
 }

/* @media (min-width:900px){
  .kyc_view_page{
   overflow: scroll;
  }
} */

.other_images_container{
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 1.5rem;
}
.inner_other_images_container{
  max-width: 200px;
  max-height: 200px;
}
.other_images_container img{
width: 100%;
height: 100%;
object-fit: contain;
}
/* customStyles.css */
.custom-phone-input .PhoneInputInput {
  border: none;
  box-shadow: none;
  outline: none;
  /* padding: 0.8rem; */
  /* border-bottom:  1px solid #a5a4a4; */
}

.custom-phone-input .PhoneInputCountry {
  border: none;
  box-shadow: none;
  outline: none;
}
.no-type {
  pointer-events: none;
}

.brouse_container_image{
  width: 70%;
   margin: auto;
    display: flex;
     gap: 10px;
     justify-content: start;
      align-items: center;
}
.mobile_document_name{
  display: block;
}
.number_input::-webkit-outer-spin-button,
.number_input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}
.number_input[type=number]{
  -moz-appearance: textfield;
}
.section2_btn_container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.read_question_container{
padding: 2rem;
width: 90%;
}
.live_photo_btn{
  background-color: #C6222F;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
}
.profile_name_container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/* #root{
  min-height: 210vh !important;
} */
@media (min-width:200px) and (max-width:800px) {
  #date_of_birth{
    max-width: 140px;
  }
  .read_question_container{
  padding:1rem;
  width: 100%;
  }
  .section2_btn_container{
flex-direction: column;
  }
  .custom-phone-input_mobile_number{
    min-width: 160px !important;
  }
  .personal_info_container{
    padding: 0 0;
  }
  .personal_content{
    width: 100%;
  }
  .personal_content_professinal{
    display: flex;
    flex-direction: column;
  }
  .brouse_container_image{
    flex-direction: column;
  }
  .other_images_container{
    /* display: flex;
    gap: 2rem;
    flex-direction: column; */
  }
  .login_page_container{
    height: 92vh;
    width: 100%;
    position: relative;
    background-color: #F9F6F2;
  }
  .login_page_button{
    /* display: block; */
    /* margin: auto; */
    padding: 0.7rem 4rem !important;
  }
  .inner_loginform_container_mobile{
    width: 90% !important;
    padding: 3rem 0 !important;
  }
  .profile_page{
   flex-direction: column;
   gap: 2rem;
   padding: 0;
   padding-top: 1rem;
  }
  .mobile_document_name{
    display: none;
  }
  .header_dropdown{
    right: 1px;
  }
  .logo_container{
    width: 100px;
    height: 30px;
    align-items: center;
  }
  .menu_container{
    padding: 0 1rem;
  }
  .radio_container{
    width: 100%;
  }
  .images_icon{
    width: 25px;
    height: 25px;
  }
  .title{
    /* text-align: center; */
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .inner_login_sidebar{
    align-items: center;
    flex-direction: column;
  }
  .btn_container{
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 1rem;
    margin-top: 1rem;
  }
  .navigation{
    flex-direction: column;
    gap: 0px;
  }
  .navigation p{
    text-align: center;
    font-size: 0.67rem;
  }
  .login_sidebar{
   flex: 0; 
  }
  .login_sidebar_multiform{
    flex: 0.8;
  }
  .loginBtnBack{
    max-width: max-content;
    margin: auto;
    padding: 0.7rem 1rem;
  }
  .loginBtn{
    max-width: max-content;
    margin: auto;
    padding: 0.7rem 1rem;
  }
  .login_form_container{
    padding: 0;
    display: flex;
    align-items: center;
  }
  .inpufeild{
    min-width: 100%;
    margin: auto;
  }
  .inner_loginform_container{
    padding: 0;
    padding-top: 2rem;
    /* width: 100%; */
  }
  .copy_right_container{
    /* position: absolute; */
    bottom: 0;
  }
  .login_copy_right_container{
    height: max-content !important;
    width: 100% !important;
    position: absolute !important;
    bottom: 0 !important;
  }
  .login_form_container{
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 0.5rem;
  }
  .login_form_container_mobile{
    padding: 0 0 0 0 !important;
    width: 100%;
        display: flex;
        align-items: center;
  }
  .select_height{
    padding: 0.8rem;
  }
  .textarea_class{
    width: 100% !important;
  }
  .photograph_title{
    flex-direction: column;
    align-items: start !important;
    gap: 0 !important;
    padding-left: 0.8rem;
    
  }
  .inner_loginform_container_family{
    width: 100% !important;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .react-datepicker-wrapper{
    max-width: 100px;
    /* background-color: red !important; */
  }
}

@media (min-width:700px){
  .inner_loginform_container_family{
    width: 50% !important;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}


.custom_capitalize{
  text-transform: capitalize;
}

input::placeholder{
  text-transform: none;
}



@media (min-width:1600px) and (max-width:2560px) {

.login_section{
  width: 100%;
  min-height: 93vh;
  max-height: max-content;
  display: flex;
}
}

@media (min-width:2570px){

  .login_section{
    width: 100%;
    min-height: 93vh;
    max-height: max-content;
    display: flex;
  }
  }

/* @media (min-width:1951px) and (max-width:2560px) {

  .login_section{
    width: 100%;
    min-height: 100vh;
    max-height: max-content;
    display: flex;
  }
  } */